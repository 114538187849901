import handleJSONResponse from 'utils/handleResponse'
import { EnterpriseDataProps, EnterpriseDataResult } from './types'

export const getEnterpriseData = async <T extends EnterpriseDataResult>(
  data: EnterpriseDataProps,
) => {
  const params = new URLSearchParams(data as any)
  const response = await fetch('/api/getEnterpriseData?' + params.toString())
  return handleJSONResponse<T[]>(response)
}

interface EnterpriseContactsProps {
  countryCode: string
  employeeType: 'Sales' | 'CustomerSupport' | 'Service'
}

export const getEnterpriseContacts = async ({
  countryCode,
  employeeType,
}: EnterpriseContactsProps) => {
  const r = getEnterpriseData({
    cmd: 'GetContacts',
    countryCode,
    employeeType,
  })

  return r
}
