import { ListListSectionCardLayoutEnum } from 'framework/strapi/types'
import { FC } from 'react'

interface GridProps {
  cardLayout?: ListListSectionCardLayoutEnum
  children?: React.ReactNode
}

const Grid: FC<GridProps> = ({ cardLayout, children }) => {
  const css = {
    grid: '',
  }
  switch (cardLayout) {
    case 'Small':
      css.grid =
        'grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-x-3 gap-y-8 md:gap-x-4 lg:gap-x-5 items-start'
      break
    case 'SmallMedium':
      css.grid =
        'grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-8 lg:gap-x-5 items-start'
      break
    case 'Large':
      css.grid =
        'grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-8 lg:gap-x-5 items-start'
      break
    case 'Auto':
      css.grid =
        'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-auto gap-x-4 gap-y-8 lg:gap-x-5 items-start'
      break
    // case 'Auto':
    //   css.grid = 'grid grid-cols-auto gap-x-4 gap-y-8 lg:gap-x-5 items-start'
    //   break
    default:
      css.grid =
        'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-8 lg:gap-x-5 items-start'
      break
  }

  return <div className={css.grid}>{children}</div>
}

export default Grid
