import type {
  ElasticAppSearchQuery,
  SortDirection,
  SubFilterQuery,
} from '@elastic/app-search-javascript'
import type { Props as ProductCardProps } from 'components/list/ProductCard'
import type { Props as ContactCardProps } from 'components/list/ContactCard'
import type { Props as StoryCardProps } from 'components/list/StoryCard'
import type { Props as LinkCardProps } from 'components/list/LinkCard'
import type { Props as FeatureCardProps } from './FeatureCard'
import type { ID, PartialProduct } from 'framework/common/types'
import { getSearchClient } from 'framework/elastic'
import type {
  ElasticSearchProduct,
  ElasticSearchStory,
} from 'framework/elastic/types'
import type {
  CommonBackground,
  ListCategoryItem,
  ListContactItem,
  ListContactsItem,
  ListFeatureItem,
  ListLinkItem,
  ListProductItem,
  ListStoriesItem,
  ListStoryItem,
  StrapiComponent,
} from 'framework/strapi/types'
import { type ComponentType, useEffect, useState } from 'react'
import type { Dictionary } from 'utils/dictionary'
import { mapProductResult } from 'framework/elastic/products'
import translate from 'utils/translate'
import dynamic from 'next/dynamic'
import { getLanguageFromChannel } from 'utils/localization'
import getFirstPublishedLayerFromBackground from 'utils/getFirstPublishedLayerFromBackground'
import { productGroup as group } from 'framework/elastic/queries'
import { getEnterpriseData } from 'framework/enterprise'
import type { EnterpriseContactDataResult } from 'framework/enterprise/types'
import { sortBy } from 'lodash'
import { parseId } from 'framework/elastic/utils'

interface CategoryProps {
  id: string
  sort: string
  count: number
}

const multiSearchCategories = (categories: CategoryProps[], locale: string) =>
  getSearchClient(locale).multiSearch<ElasticSearchProduct>(
    categories.map(({ id, sort, count }) => {
      const lastSegment = id.includes('/')
        ? id.substring(id.lastIndexOf('/') + 1)
        : id
      const [sortBy, sortOrder = 'desc'] = sort.toLowerCase().split('_')
      const options: ElasticAppSearchQuery = {
        group,
        sort: {
          [sortBy === 'name' ? 'bp_name' : (sortBy as string)]:
            sortOrder as SortDirection,
        },
        page: {
          size: count,
        },
        filters: {
          any: [],
          all: [{ type: ['product'] }],
        },
      }
      options.filters!.any!.push({ category_id: [lastSegment] }) //main category
      options.filters!.any!.push({ parent_category_ids: [lastSegment] }) //parent categories
      options.filters!.any!.push({ category_ids: [lastSegment] }) //secondary categories
      return {
        query: '',
        options,
      }
    }),
  )

const searchProducts = (
  productIds: string[],
  locale: string,
  splitToIndividual = false,
) =>
  getSearchClient(locale).search<ElasticSearchProduct>('', {
    group: splitToIndividual ? undefined : group,
    filters: { bp_id: productIds },
    page: { size: 100 },
  })

const searchVariants = (variantIds: string[], locale: string) =>
  getSearchClient(locale).search<ElasticSearchProduct>('', {
    group,
    filters: { v_id: variantIds },
    page: { size: 100 },
  })

const multiSearchStories = (stories: ListStoriesItem[], locale: string) => {
  const lang = getLanguageFromChannel(locale)
  return getSearchClient(lang || 'en', 'cms').multiSearch<ElasticSearchStory>(
    stories.map((story) => {
      const options: ElasticAppSearchQuery = {
        page: {
          size: story.count || 12,
        },
      }

      const tags = story.tags?.split('|').reduce<string[]>((a, t) => {
        t = t?.trim()
        if (t) {
          a.push(t)
        }
        return a
      }, [])

      if (story.sort !== 'Recommended_Asc') {
        const [sortField, sortDir] = story.sort.split('_')
        const field = sortField === 'Name' ? 'title' : 'date'
        options.sort = {
          [field]: sortDir === 'Asc' ? 'asc' : 'desc',
        }
      }
      const category = story.category?.trim()
      const all: SubFilterQuery[] = [
        { type: 'story' },
        { channel: ['*', locale] },
      ]
      if (tags?.length) {
        all.push({ tags })
      }
      if (category) {
        all.push({ category })
      }
      options.filters = {
        all,
        none: [{ channel: [`!${locale}`] }],
      }

      return {
        query: '',
        options,
      }
    }),
  )
}

const mapStoryResult = (
  locale: string,
  story: ElasticSearchStory,
  item: ListStoriesItem,
): StoryCardProps => {
  const id = `${item.__component}_${item.id}_${story.id}`
  const background = story.background.raw
    ? (JSON.parse(story.background.raw) as CommonBackground)
    : null

  const image = getFirstPublishedLayerFromBackground(locale, background)
  const props: StoryCardProps = {
    id,
    __component: 'list.story-item',
    authorName: story.author.raw ?? '',
    title: story.title.raw ?? '',
    desc: story.subheading.raw ?? '',
    image,
    cta: {
      __component: 'common.link',
      id: `${story.id}.link`,
      title:
        (story.readingtime.raw ?? '')
          ? translate(
              'storyReadingTime',
              story.readingtime.raw?.toString() ?? '',
            )
          : (story.title.raw ?? ''),
      url: {
        type: 'page',
        id: story.slug.raw ?? '',
        src: `stories/${story.slug.raw}`,
      },
    },
    category: story.category.raw ?? '',
    image_canto: null,
  }
  return props
}

type CardTypeString = 'product' | 'feature' | 'contact' | 'link' | 'story'

export interface CardType {
  id: ID
  type: CardTypeString
  props: object
}

interface CreateCardProps {
  items: StrapiComponent[] | null
  locale: string
  country: string
  setShowPreview?: (show: boolean) => void
  setSelectedProduct?: (product: PartialProduct | null) => void
}

export const createCards = async ({
  items,
  locale,
  country,
  setShowPreview,
  setSelectedProduct,
}: CreateCardProps) => {
  if (!items || !items.length) {
    return []
  }

  const productIds: string[] = []
  const variantIds: string[] = []
  const categories: CategoryProps[] = []
  const stories: ListStoriesItem[] = []
  const contacts: ListContactsItem[] = []

  for (const item of items) {
    if (!item) continue

    switch (item.__component) {
      case 'list.product-item': {
        const props = item as ListProductItem
        const id = props.url?.id
        if (id) {
          if (props.url?.type === 'variant') {
            variantIds.push(parseId(id as string))
          } else {
            productIds.push(parseId(id as string))
          }
        }
        break
      }
      case 'list.category-item': {
        const { count, sort, url } = item as ListCategoryItem
        const id = url?.id
        if (id) {
          categories.push({ id: parseId(id as string), sort, count })
        }
        break
      }
      case 'list.stories-item': {
        stories.push(item as ListStoriesItem)
        break
      }
      case 'list.contacts-item': {
        contacts.push(item as ListContactsItem)
        break
      }
    }
  }

  // Fetch in parallell
  const [
    productMap,
    categoryMap,
    storyMap,
    contactsMap,
    variantMap,
    variantsFromProducts,
  ] = await Promise.all([
    createProductMap(productIds, locale),
    createCategoryMap(categories, locale),
    createStoryMap(stories, locale),
    createContactsMap(contacts, country),
    createVariantMap(variantIds, locale),
    createVariantsFromProductMap(productIds, locale),
  ])

  return items.reduce<CardType[]>((a, item) => {
    switch (item.__component) {
      case 'list.product-item': {
        const data = item as ListProductItem
        const id = data.url ? parseId(data.url.id as string) : null
        if (!id) break
        let product: PartialProduct | undefined
        if (data.url?.type === 'variant') {
          product = variantMap ? variantMap[id] : undefined
        } else {
          product = productMap ? productMap[id] : undefined
        }
        if (!product) break
        const props: ProductCardProps = {
          product,
        }
        const cardType: CardType = {
          id: `${item.__component}_${item.id}`,
          type: 'product',
          props,
        }

        if (product.splitToIndividual && variantsFromProducts) {
          const variants = variantsFromProducts
            ? variantsFromProducts.filter((v) => v.id === id)
            : undefined
          if (variants) {
            for (const variant of variants) {
              const props: ProductCardProps = {
                product: variant,
                setShowPreview,
                setSelectedProduct,
                uiSource: 'carousel',
              }
              const cardType: CardType = {
                id: `${item.__component}_${item.id}_${variant.id}`,
                type: 'product',
                props,
              }
              a.push(cardType)
            }
          }
        } else {
          a.push(cardType)
        }

        break
      }
      case 'list.category-item': {
        const data = item as ListCategoryItem
        const id = data.url?.id
        const products = id && categoryMap ? categoryMap[id] : undefined
        if (!products) return a
        for (const product of products) {
          const props: ProductCardProps = {
            product,
            setShowPreview,
            setSelectedProduct,
            uiSource: 'carousel',
          }
          const cardType: CardType = {
            id: `${item.__component}_${item.id}_${product.id}`,
            type: 'product',
            props,
          }
          a.push(cardType)
        }
        break
      }
      case 'list.feature-item': {
        const data = item as ListFeatureItem
        const id = `${item.__component}_${item.id}`
        const props: FeatureCardProps = { ...data, id }
        const cardType: CardType = {
          id,
          type: 'feature',
          props,
        }
        a.push(cardType)
        break
      }
      case 'list.contact-item': {
        const data = item as ListContactItem
        const id = `${item.__component}_${item.id}`
        const props: ContactCardProps = { ...data, id }
        const cardType: CardType = {
          id,
          type: 'contact',
          props,
        }
        a.push(cardType)
        break
      }
      case 'list.link-item': {
        const data = item as ListLinkItem
        const id = `${item.__component}_${item.id}`
        const props: LinkCardProps = { ...data, id }
        const cardType: CardType = {
          id,
          type: 'link',
          props,
        }
        a.push(cardType)
        break
      }
      case 'list.story-item': {
        const data = item as ListStoryItem
        const id = `${item.__component}_${item.id}`
        const props: StoryCardProps = { ...data, id }
        const cardType: CardType = {
          id,
          type: 'story',
          props,
        }
        a.push(cardType)
        break
      }
      case 'list.stories-item': {
        const items = storyMap[item.id]
        if (items) {
          for (const props of items) {
            const cardType: CardType = {
              id: props.id,
              type: 'story',
              props,
            }
            a.push(cardType)
          }
        }
        break
      }
      case 'list.contacts-item': {
        const items = contactsMap[item.id]
        if (items) {
          for (const props of items) {
            const cardType: CardType = {
              id: props.id,
              type: 'contact',
              props,
            }
            a.push(cardType)
          }
        }
      }
    }
    return a
  }, [])
}

export const useCards = ({
  items,
  locale,
  country,
  setShowPreview,
  setSelectedProduct,
}: CreateCardProps) => {
  const [cards, setCards] = useState<CardType[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const run = async () => {
      try {
        setLoading(true)
        const cards = await createCards({
          items,
          locale,
          country,
          setShowPreview,
          setSelectedProduct,
        })
        setCards(cards)
      } catch (e) {
        console.error(e)
      }
      setLoading(false)
    }
    if (items?.length && locale) {
      run()
    }
  }, [items, locale, country, setShowPreview, setSelectedProduct])
  return { cards, loading }
}

// eslint-disable-next-line no-unused-vars
export const cardComponentMap: { [key in CardTypeString]: ComponentType<any> } =
  {
    link: dynamic(import('./LinkCard'), { ssr: false }),
    contact: dynamic(import('./ContactCard'), { ssr: false }),
    feature: dynamic(import('./FeatureCard'), { ssr: false }),
    product: dynamic(import('./ProductCard'), { ssr: false }),
    story: dynamic(import('./StoryCard'), { ssr: false }),
  }

async function createProductMap(
  productIds: string[],
  locale: string,
): Promise<Dictionary<PartialProduct>> {
  if (!productIds.length) {
    return {}
  }
  const r = await searchProducts(productIds, locale)
  const productMap = r.results.reduce<Dictionary<PartialProduct>>((a, rr) => {
    a[rr.data.bp_id.raw!] = mapProductResult(rr, locale)
    return a
  }, {})
  return productMap
}

async function createVariantsFromProductMap(
  productIds: string[],
  locale: string,
): Promise<PartialProduct[]> {
  if (!productIds.length) {
    return []
  }
  const r = await searchProducts(productIds, locale, true)
  const productMap = r.results
    .reduce<PartialProduct[]>((a, rr) => {
      return a.concat(mapProductResult(rr, locale))
    }, [])
    .sort((a, b) => {
      return a.main === b.main ? 0 : a.main ? -1 : 0
    })
  return productMap
}

async function createVariantMap(
  variantIds: string[],
  locale: string,
): Promise<Dictionary<PartialProduct>> {
  if (!variantIds.length) {
    return {}
  }
  const r = await searchVariants(variantIds, locale)
  const productMap = r.results.reduce<Dictionary<PartialProduct>>((a, rr) => {
    a[rr.data.v_id.raw!] = mapProductResult(rr, locale)
    return a
  }, {})
  return productMap
}

async function createContactsMap(
  contacts: ListContactsItem[],
  country: string,
): Promise<Dictionary<ContactCardProps[]>> {
  const contactsMap: Dictionary<ContactCardProps[]> = {}
  if (!contacts.length) {
    return contactsMap
  }
  for (const c of contacts) {
    const results: EnterpriseContactDataResult[] = await getEnterpriseData({
      cmd: 'GetContacts',
      countryCode: c.countryCode
        ? c.countryCode.toUpperCase()
        : country.toUpperCase(),
      employeeType: c.employeeType || 'Sales',
    })
    contactsMap[c.id] = mapContactResult(results)
  }
  return contactsMap
}

async function createStoryMap(
  stories: ListStoriesItem[],
  locale: string,
): Promise<Dictionary<StoryCardProps[]>> {
  if (!stories.length) {
    return {}
  }
  const results = await multiSearchStories(stories, locale)
  const storyMap = results.reduce<Dictionary<StoryCardProps[]>>((a, r, i) => {
    const story = stories[i]!
    a[story.id] = r.results.map((rr) => mapStoryResult(locale, rr.data, story))
    return a
  }, {})
  return storyMap
}

async function createCategoryMap(
  categories: CategoryProps[],
  locale: string,
): Promise<Dictionary<PartialProduct[]>> {
  if (!categories.length) {
    return {}
  }
  const results = await multiSearchCategories(categories, locale)
  const categoryMap = results.reduce<Dictionary<PartialProduct[]>>(
    (a, r, i) => {
      a[categories[i]!.id] = r.results.map((rr) => mapProductResult(rr, locale))
      return a
    },
    {},
  )
  return categoryMap
}

function mapContactResult(
  results: EnterpriseContactDataResult[],
): ContactCardProps[] {
  const sortedResults = sortBy(results, (v) => Number.parseInt(v.sortOrder))
  return sortedResults.map((c) => {
    const props: ContactCardProps = {
      id: c.id,
      __component: 'list.contact-item',
      title: c.title,
      email: c.email,
      name: c.name,
      phone: c.phone,
      whatsapp: c.whatsApp,
      message: null,
      meeting: c.bookMeetingUrl || null,
      image: c.imgUrl
        ? {
            id: -11282822,
            __component: 'cms.gallery-item',
            caption: c.name,
            formats: {},
            mime: 'image/jpeg',
            width: 500,
            height: 750,
            name: c.name,
            alternativeText: c.name,
            url: c.imgUrl,
          }
        : null,
      image_canto: null,
    }
    return props
  })
}
