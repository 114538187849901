import { ListListSectionCardLayoutEnum } from 'framework/strapi/types'
import React, { FC } from 'react'

interface Props {
  layout?: ListListSectionCardLayoutEnum
}

const PlaceholderCard: FC<Props> = ({ layout }) => {
  const css = {
    cardInner: 'aspect-w-4 aspect-h-5 bg-neutral-lightest animate-pulse',
  }

  if (layout === 'Small') {
    css.cardInner = 'aspect-w-4 aspect-h-5 bg-neutral-lightest  animate-pulse'
  }

  if (layout === 'Large') {
    css.cardInner =
      'aspect-w-4 aspect-h-5 md:aspect-w-5 md:aspect-h-4 lg:aspect-w-4 lg:aspect-h-5 xl:aspect-w-1 xl:aspect-h-1 bg-neutral-lightest animate-pulse'
  }

  return (
    <article className="relative overflow-hidden group">
      <div aria-hidden="true" className={css.cardInner}></div>
    </article>
  )
}

export default PlaceholderCard
