import isTouchDevice from 'utils/is-touch-device'
import { breakPointMin, BreakPointTypes } from 'utils/breakpoints'
import { PrevButton, NextButton } from './Buttons'
import React, { Children, FC, useCallback, useEffect, useState } from 'react'
import useEmblaCarousel, { EmblaOptionsType } from 'embla-carousel-react'
import { Dictionary } from 'utils/dictionary'
import {
  ListListSectionCardLayoutEnum,
  ListListSectionCardsGridLayoutEnum,
} from 'framework/strapi/types'
import cn from 'classnames'

interface Props {
  layout?: ListListSectionCardsGridLayoutEnum | 'Upsell'
  cardLayout?: ListListSectionCardLayoutEnum
  children?: React.ReactNode
}

// Carousel Options
const emblaOptions: Dictionary<EmblaOptionsType> = {
  disableCarousel: {
    align: 'start',
    watchDrag: false,
    loop: false,
  },
  touchDevice: {
    align: 'start',
    containScroll: 'trimSnaps',
    watchDrag: true,
    dragFree: true,
  },
  desktopDevice: {
    align: 'start',
    containScroll: 'trimSnaps',
    watchDrag: true,
    dragFree: false,
  },
}

const Carousel: FC<Props> = ({ layout, cardLayout, children }) => {
  let disableCarouselBreakPoint: BreakPointTypes | undefined

  // Initialize Carousel
  const [viewportRef, embla] = useEmblaCarousel()

  // Initialize Buttons
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

  // Button Visibility
  const [btnVisible, setBtnVisible] = useState(false)

  // Button Navigation
  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])

  const onSelect = useCallback(() => {
    if (!embla) return
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
  }, [embla])

  const setEmblaOptions = useCallback(() => {
    setBtnVisible(!isTouchDevice() || breakPointMin('lg')) //Set visibililty for buttons

    if (breakPointMin(disableCarouselBreakPoint)) {
      setBtnVisible(false)
      return emblaOptions.disableCarousel
    }

    if (isTouchDevice()) return emblaOptions.touchDevice
    return emblaOptions.desktopDevice
  }, [disableCarouselBreakPoint])

  const initEmbla = useCallback(() => {
    if (!embla) return
    embla.reInit(setEmblaOptions()) // Initialize Embla Options
  }, [embla, setEmblaOptions])

  useEffect(() => {
    if (!embla) return

    embla.scrollTo(0) // Reset carousel to starting position

    initEmbla()
    onSelect() // Enable/Disable Prev and Next Buttons, Update Dot Buttons

    embla.on('select', () => {
      onSelect()
    }) // Enable/Disable Buttons on navigation

    embla.on('resize', () => {
      initEmbla()
      onSelect() // Enable/Disable Buttons
    })
  }, [disableCarouselBreakPoint, embla, initEmbla, onSelect, children])

  const css = {
    emblaContainer: 'embla__container flex -ml-3 md:-ml-4 lg:-ml-5 select-none',
    slide:
      'embla__slide flex flex-col relative pl-3 md:pl-4 lg:pl-5 flex-shrink-0 w-11/12 md:w-1/2 lg:w-1/3 xl:w-1/4',
  }

  if (cardLayout === 'Small') {
    css.slide =
      'embla__slide flex flex-col relative pl-3 md:pl-4 lg:pl-5 flex-shrink-0 w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/6'
  }

  if (cardLayout === 'Large') {
    css.slide =
      'embla__slide flex flex-col relative pl-3 md:pl-4 lg:pl-5 flex-shrink-0 w-11/12 md:w-full lg:w-1/2'
  }

  if (layout === 'CarouselTiles') {
    disableCarouselBreakPoint = 'lg'
    css.emblaContainer =
      'embla__container flex relative flex select-none -ml-3 md:-ml-4 lg:ml-0 lg:grid lg:gap-x-5 lg:gap-y-8 lg:grid-cols-3 xl:grid-cols-4'
    css.slide =
      'embla__slide flex flex-col relative flex-shrink-0 w-11/12 pl-3 md:w-1/2 md:pl-4 lg:flex-none lg:w-auto lg:p-0'

    if (cardLayout === 'Small') {
      css.emblaContainer =
        'embla__container relative flex select-none -ml-3 md:-ml-4 lg:ml-0 lg:grid lg:gap-x-5 lg:gap-y-8 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6'
      css.slide =
        'embla__slide flex flex-col relative flex-shrink-0 w-1/2 pl-3 md:w-1/3 md:pl-4 lg:flex-none lg:w-auto lg:p-0'
    }

    if (cardLayout === 'Large') {
      css.emblaContainer =
        'embla__container relative flex select-none -ml-3 md:-ml-4 lg:ml-0 lg:grid lg:gap-x-5 lg:gap-y-8 lg:grid-cols-2'
      css.slide =
        'embla__slide flex flex-col relative flex-shrink-0 w-11/12 pl-3 md:w-full md:pl-4 lg:flex-none lg:w-auto lg:p-0'
    }

    if (cardLayout === 'Auto') {
      css.emblaContainer =
        'embla__container relative flex select-none -ml-3 md:-ml-4 lg:ml-0 lg:grid lg:gap-x-5 lg:gap-y-8 lg:grid-cols-auto'
      css.slide =
        'embla__slide flex flex-col relative flex-shrink-0 w-11/12 pl-3 md:w-1/2 md:pl-4 lg:flex-none lg:w-auto lg:p-0'
    }
  } else if (layout === 'CarouselNarrow') {
    css.emblaContainer =
      'embla__container flex -ml-3 md:-ml-4 lg:-ml-5 select-none'
    css.slide =
      'embla__slide flex flex-col relative pl-3 md:pl-4 lg:pl-5 flex-shrink-0 w-11/12 md:w-1/2'
  } else if (layout === 'Upsell') {
    // disableCarouselBreakPoint = 'lg'
    css.emblaContainer =
      'embla__container flex -ml-3 md:-ml-4 lg:ml-0 select-none gap-2'

    css.slide =
      'embla__slide flex flex-col relative flex-shrink-0 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/2'
  }
  const childArray = Children.toArray(children)
  if (!childArray.length) return null
  return (
    <div className="embla relative">
      <div
        className={cn('embla__viewport relative', {
          'lg:overflow-x-hidden': layout === 'Upsell',
        })}
        ref={viewportRef}
      >
        <div className={css.emblaContainer}>
          {Children.map(childArray, (child, index) => (
            <div key={index} className={css.slide}>
              {child}
            </div>
          ))}
        </div>
      </div>
      <PrevButton
        onClick={scrollPrev}
        enabled={prevBtnEnabled}
        visible={btnVisible}
      />
      <NextButton
        onClick={scrollNext}
        enabled={nextBtnEnabled}
        visible={btnVisible}
      />
    </div>
  )
}

export default Carousel
