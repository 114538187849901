import { CommonBackground } from 'framework/strapi/types'
import { isComponentPublishedInChannel } from './isInChannel'

const getFirstPublishedLayerFromBackground = (
  channel: string,
  background: CommonBackground | null,
) => {
  if (!background?.layers) return null
  for (const l of background.layers) {
    if (l.image && isComponentPublishedInChannel(channel, l)) return l.image
  }
  return null
}

export default getFirstPublishedLayerFromBackground
