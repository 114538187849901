import React, { type FC, useMemo } from 'react'
import cn from 'classnames'
import { cardComponentMap, useCards } from './useCards'
import Carousel from './Carousel'
import PlaceholderCard from './PlaceholderCard'
import type { ListListSection } from 'framework/strapi/types'
import Grid from './Grid'
import translate from 'utils/translate'
import { useAppSelector } from 'store'
import Container from 'components/ui/Container'
import Link from 'components/ui/Link'
import type { PartialProduct } from 'framework/common/types'

interface Props extends ListListSection {
  setShowPreview?: (show: boolean) => void
  setSelectedProduct?: (product: PartialProduct | null) => void
}

const ListBlock: FC<Props> = ({
  heading,
  description,
  link,
  showInfo,
  list,
  cardLayout,
  settings,
  cardsGridLayout,
  setShowPreview,
  setSelectedProduct,
}) => {
  const { locale, country } = useAppSelector((s) => s.channel)

  settings = settings || {}
  const { colorTheme = 'Default', container = 'Default' } = settings
  // CSS
  const css = {
    // heading: 'font-bold text-h-xl lg:text-h-2xl',
    // heading: 'font-bold text-h-2xl xl:text-h-3xl',
    heading: 'font-bold text-h-2xl md:text-h-3xl xl:text-h-4xl',
    link: 'font-medium text-sm md:text-base xl:text-lg underline_ hover:no-underline inline-flex items-end group',
    infoLabel: 'font-medium text-sm',
    desc: 'mt-4 font-medium text-base md:text-lg 2xl:text-xl max-w-xl 2xl:max-w-4xl_',
  }

  switch (colorTheme) {
    case 'Dark':
      css.heading = cn(css.heading, 'text-white')
      css.link = cn(css.link, 'text-white')
      css.infoLabel = cn(css.infoLabel, 'text-white')
      css.desc = cn(css.desc, 'text-white')
      break
    default:
      css.heading = cn(css.heading, 'text-primary')
      css.link = cn(css.link, 'text-primary')
      css.infoLabel = cn(css.infoLabel, 'text-primary')
      css.desc = cn(css.desc, 'text-primary')
  }

  const { cards, loading } = useCards({
    items: list?.items || null,
    locale,
    country: country || '',
    setShowPreview,
    setSelectedProduct,
  })

  const cardElements = useMemo(
    () =>
      !cards.length && loading
        ? [...Array(8)].map((_, i) => (
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            <PlaceholderCard key={i} layout={cardLayout} />
          ))
        : cards.map((c) => {
            const Comp = cardComponentMap[c.type]
            return <Comp key={c.id} {...c.props} layout={cardLayout} />
          }),
    [cardLayout, cards, loading],
  )

  const infoLabel = `${cards.length} ${translate('items').toLowerCase()}`
  const hasLink = !!(link?.title && link.url?.src)

  return (
    <Container
      layout={container === 'Default' ? 'Fluid' : container}
      height="Grow"
      align="Center"
      overflow="XHidden"
    >
      {(heading || hasLink || description) && (
        <div className="mb-8 md:mb-8 lg:mb-10">
          {/* Header */}
          {(heading || hasLink) && (
            <div className="flex flex-wrap items-center justify-between gap-x-4 gap-y-3">
              {heading && <h2 className={css.heading}>{heading}</h2>}
              {showInfo && <span className={css.infoLabel}>{infoLabel}</span>}
              {link?.title && link.url?.src && (
                <Link href={link.url.src} className={css.link}>
                  <span className="inline-block transition-all duration-300 border-b-2 border-primary group-hover:border-transparent">
                    {link.title}
                  </span>
                </Link>
              )}
            </div>
          )}
          {description && <p className={css.desc}>{description}</p>}
        </div>
      )}
      {cardsGridLayout === 'Tiles' ? (
        <Grid cardLayout={cardLayout}>{cardElements}</Grid>
      ) : (
        <Carousel layout={cardsGridLayout} cardLayout={cardLayout}>
          {cardElements}
        </Carousel>
      )}
    </Container>
  )
}

export default ListBlock
